import React, { useState, useEffect, useRef } from "react";
import NavBar from "../NavBar.js";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileExport, faPrint, faInfoCircle, faTrashAlt, faHouse, faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faCheckCircle, faTimesCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import logo from "../Images/logo.png";
import NewPurchasesScheme from "./newSchemePurchase.js";
import ReferenceDetails from "./ModalReferenceDetails.js";

export default function SchemPurchase() {
  const [showModal, setShowModal] = useState(false);
  const [showRefModal, setShowRefModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchRef, setSearchRef] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [scheme, setScheme] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    customerName: "",
    referenceId: "",
    customerMobile: "",
  });

  const [page, setPage] = useState(1);       // Current page number
  const [limit, setLimit] = useState(10);    // Number of entries per page
  const [totalPages, setTotalPages] = useState(1);  // Total pages from backend


  useEffect(() => {
    const interval = setInterval(() => {
      fetchSchemeName();
    }, 1000); // Fetch every 1 second
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [page, limit]);

  const fetchSchemeName = async () => {
    try {
      const response = await fetch(`https://www.empairindia.com/svv/getAllCustomers?page=${page}`);
      if (!response.ok) {
        throw new Error("Failed to fetch schemes");
      }
      const data = await response.json();

      // Convert schemes object with numerical keys to an array
      const schemesArray = Object.values(data.schemes);
      setProductsData(data.productsData);
      setTotalPages(data.totalPages);
      setScheme(schemesArray); // Set as an array for table mapping
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle changes to page and limit, then fetch data accordingly
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage); // Set new page number
    }
  };

  const handleNextPage = () => setPage((prev) => (prev < totalPages ? prev + 1 : prev));
  const handlePrevPage = () => setPage((prev) => (prev > 1 ? prev - 1 : prev));
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    setPage(1); // Reset to first page on limit change
  };

  // Determine page numbers to display around the current page
  const pageNumbers = [];
  const startPage = Math.max(1, page - 2);
  const endPage = Math.min(totalPages, page + 2);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }


  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleRefModalClose = () => {
    setShowRefModal(false);
  };
  const handleSaveValue = (value) => {
    setTableData([...tableData, value]);
  };

  const searchReferenceId = (referenceId) => {
    const minLength = 4;
    const matches = scheme.flatMap((scheme) =>
      scheme.customerData.flatMap((customer) =>
        customer.reference.filter(
          (ref) =>
            ref.referenceId === referenceId ||
            (ref.referenceId.startsWith(referenceId.slice(0, -1)) &&
              ref.referenceId.length === referenceId.length - 1)
        )
      )
    );
    setSearchRef(matches);

    if (referenceId.length > minLength) {
      searchReferenceId(referenceId.slice(0, -1));
    }
  };

  const handleReferenceIdClick = (referenceId, customer) => {
    setSelectedCustomer({
      customerName: customer.customerName,
      referenceId: customer.referenceId,
      customerMobile: customer.customerMobile,
      referenceAmount: customer.referenceAmount,
    });

    setShowRefModal(true);

    const minLength = 3;
    const maxLevel = 14;
    const placeholderData = {
      customerName: "Empair",
      referenceId: "EMP",
      customerMobile: 9999999999,
      schemePurchaseId: "EMP01",
    };

    const lengthToAmountMap = {
      level1: 500,
      level2: 125,
      level3: 75,
      level4: 25,
      level5: 15,
      level6: 5,
      level7: 5,
      level8: 5,
      level9: 5,
      level10: 5,
      level11: 5,
      level12: 5,
      level13: 5,
      level14: 5,
    };

    const removeLastNumberAndLetter = (id) => {
      const regexPattern = /(\d+)[A-Za-z]$/;
      return regexPattern.test(id) ? id.replace(regexPattern, '') : id;
    };

    const searchReferenceId = (id, level = 1) => {
      if (id.length < minLength || level > maxLevel) return;

      const levelKey = `level${level}`;
      const newId = removeLastNumberAndLetter(id);

      const matches = scheme.flatMap((schemeItem) => {
        const customer = schemeItem.customerData;
        if (
          customer.referenceId === newId ||
          (customer.referenceId.startsWith(newId.slice(0, -1)) &&
            customer.referenceId.length === newId.length - 1)
        ) {
          return [customer];
        }
        return [];
      });

      setSearchRef((prevResults) => {
        const uniqueMatches = matches.filter(
          (newMatch) =>
            !prevResults.some((existing) => existing.referenceId === newMatch.referenceId)
        );

        const updatedMatches = uniqueMatches.map((match) => ({
          ...match,
          Length: levelKey,
          referenceAmount: lengthToAmountMap[levelKey],
        }));

        return [...prevResults, ...updatedMatches];
      });

      console.log(`${levelKey} = ${newId}`);
      searchReferenceId(newId, level + 1);
    };

    setSearchRef([]);
    searchReferenceId(referenceId);

    setSearchRef((prevResults) => {
      const populatedLevels = prevResults.map((result) => result.Length);

      for (let i = 1; i <= maxLevel; i++) {
        const levelKey = `level${i}`;
        if (!populatedLevels.includes(levelKey)) {
          prevResults.push({
            ...placeholderData,
            Length: levelKey,
            referenceAmount: lengthToAmountMap[levelKey],
          });
        }
      }

      return prevResults;
    });
  };


  return (
    <div>
      {loading && (
        <div className="loader-overlay">
          <div className="clock-loader"></div>
        </div>
      )}
      <nav className="navbar navbar-light ">
        <div className="container-fluid">
          <Link className="navbar-brand ms-5 d-flex align-items-center" to="/">
            <img
              src={logo}
              alt="logo"
              width="100px"
              className="d-inline-block align-text-top"
            />
            <strong className="fs-2 ">EMPAIR MARKETING PVT LTD</strong>
          </Link>
          <div className="d-flex" style={{ width: "600px" }}>
            <FontAwesomeIcon icon={faHouse} className=" me-5 mt-4" />
            <FontAwesomeIcon
              icon={faPowerOff}
              onClick={""}
              className="text-danger me-5 mt-4"
            />
            <div className="d-flex">
              <FontAwesomeIcon icon={faUser} className="me-3 mt-4" />
              <ul className="list-unstyled mb-1" style={{ width: "150px" }}>
                <li className="me-2">Employee</li>
                <li className="me-2">Place</li>
                <li className="me-2">Time</li>
                <li>Date</li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="marquee  px-5 m-2">
        <marquee className="text-white" behavior="scroll" direction="left">
          New Updates : Welcome to EMPAIR MARKETING PVT LTD....Have a nice
          day....{" "}
        </marquee>
      </div>
      <NavBar />
      <div
        className="text-center mt-3"
        style={{ backgroundColor: "lightgrey" }}
      >
        <p>
          <b>Scheme List (Year: 2024-2025)</b>
        </p>
      </div>

      <Container>
        <Row>
          <Col sm={3}>
            <Form.Group controlId="input2">
              <Form.Label>Scheme</Form.Label>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="input4">
              <Form.Label>Added By:</Form.Label>
              <Form.Control size="sm" as="select">
                <option value="">Select an option</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="input2">
              <Form.Label>Date From</Form.Label>
              <Form.Control size="sm" type="date" />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="input3">
              <Form.Label>To</Form.Label>
              <Form.Control size="sm" type="date" />
            </Form.Group>
          </Col>
        </Row>
        {/* <div className="container">
          <ul className="responsive-table">
            <li className="table-header">
              <div className="col col-1">SL No</div>
              <div className="col col-2">Customer Name</div>
              <div className="col col-3">Phone Number</div>
              <div className="col col-4">Customer Id</div>
              <div className="col col-5">Invoice Number</div>
              <div className="col col-6">Scheme Name</div>
              <div className="col col-7">Reference Id</div>
              <div className="col col-8">Request</div>
            </li>
          <div>
            {scheme.length > 0 ? (
              scheme.map((schemeItem, index) => {
                const customer = schemeItem.customerData;

                return (
                  <li className="table-row"
                    key={index} // Using index as the key for each row
                    onClick={() => handleReferenceIdClick(customer.referenceId, customer)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="col col-1">{(page - 1) * limit + index + 1}</div>
                    <div className="col col-2">{customer.customerName}</div>
                    <div className="col col-3">{customer.customerMobile}</div>
                    <div className="col col-4">{customer.customerId}</div>
                    <div className="col col-5">{customer.invoiceNumber}</div>
                    <div className="col col-6">{schemeItem.SchemeName}</div>
                    <div className="col col-7">{customer.referenceId}</div>
                    <div className="col col-8">
                      {schemeItem.referenceData.length > 0 ? (
                        schemeItem.referenceData[0].request === 'yes' ? (
                          <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                        ) : schemeItem.referenceData[0].request === 'no' ? (
                          <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                        ) : (
                          <FontAwesomeIcon icon={faCircle} className="text-warning" />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faCircle} className="text-warning" />
                      )}
                    </div>
                  </li>
                );
              })
            ) : (
              <tr>
                <td colSpan="8">No data available</td>
              </tr>
            )}
          </div>
          </ul>
          <div className="pagination">
            <select onChange={handleLimitChange} value={limit}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
            <div className="page-numbers">
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={page === pageNumber ? "active" : ""}
                >
                  {pageNumber}
                </button>
              ))}
            </div>
            <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
            
            <div className="page-numbers">Page {page} of {totalPages}</div>
          </div>
        </div> */}
<div className="container">
            <Table className="container">
                <thead>
                    <tr>
                        <th><h4>SL No</h4></th>
                        <th><h4>Customer Name</h4></th>
                        <th><h4>Phone Number</h4></th>
                        <th><h4>Customer Id</h4></th>
                        <th><h4>Invoice Number</h4></th>
                        <th><h4>Scheme Name</h4></th>
                        <th><h4>Reference Id</h4></th>
                        <th><h4>Request</h4></th>
                    </tr>
                </thead>
                <tbody>
                    {scheme.length > 0 ? (
                        scheme.map((schemeItem, index) => {
                            const customer = schemeItem.customerData;
                            return (
                                <tr
                                    key={index}
                                    onClick={() => handleReferenceIdClick(customer.referenceId, customer)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <td>{(page - 1) * limit + index + 1}</td>
                                    <td>{customer.customerName}</td>
                                    <td>{customer.customerMobile}</td>
                                    <td>{customer.customerId}</td>
                                    <td>{customer.invoiceNumber}</td>
                                    <td>{schemeItem.SchemeName}</td>
                                    <td>{customer.referenceId}</td>
                                    <td>
                                        {schemeItem.referenceData.length > 0 ? (
                                            schemeItem.referenceData[0].request === 'yes' ? (
                                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                            ) : schemeItem.referenceData[0].request === 'no' ? (
                                                <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                                            ) : (
                                                <FontAwesomeIcon icon={faCircle} className="text-warning" />
                                            )
                                        ) : (
                                            <FontAwesomeIcon icon={faCircle} className="text-warning" />
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="8">No data available</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            {/* Pagination Controls */}
            {/* <div className="pagination">
                <select onChange={handleLimitChange} value={limit}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
                <div className="page-numbers">
                    {pageNumbers.map((pageNumber) => (
                        <button
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            className={page === pageNumber ? "active" : ""}
                        >
                            {pageNumber}
                        </button>
                    ))}
                </div>
                <button onClick={handleNextPage} disabled={page === totalPages}>Next</button>
                
                <div className="page-numbers">Page {page} of {totalPages}</div>
            </div> */}
        </div>

        <Row className="text-center mt-3">
          <Col xs={2} className="px-1">
            <Button
              variant="success"
              size="sm"
              className="w-50"
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} /> New
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-50">
              <FontAwesomeIcon icon={faInfoCircle} /> Details
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-50">
              <FontAwesomeIcon icon={faFileExport} /> Export
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-50">
              <FontAwesomeIcon icon={faPrint} /> Print
            </Button>
          </Col>
          <Col xs={2} className="px-1">
            <Button variant="secondary" size="sm" className="w-80">
              <FontAwesomeIcon icon={faPrint} /> Print Voucher
            </Button>
          </Col>
          <Col xs={{ span: 0, offset: 0 }} className="px-1 w-40">
            <Button variant="secondary" size="sm">
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </Button>
          </Col>
        </Row>
      </Container>

      <NewPurchasesScheme
        show={showModal}
        handleClose={handleModalClose}
        handleSave={handleSaveValue}
      />
      <ReferenceDetails
        show={showRefModal}
        handleClose={handleRefModalClose}
        searchRef={searchRef}
        customerDetails={selectedCustomer}
      />
    </div>
  );
}
